import { NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { FdrError } from 'components/domain/fdr-error';
import { Fdr404PageContentQuery } from 'schemas/query/404/fdr-404-page-content.generated';

export { getServerSideProps } from 'components/page/error/error.gssp';

function InternalServerError(): ReactElement {
  const [t] = useTranslation('common');
  return (
    <FdrError title={t('common:error.title')} text={t('common:error.text')} />
  );
}

interface IErrorPageProps {
  statusCode?: number;
  content?: Pick<Fdr404PageContentQuery['fdr404Page'], 'title' | 'text'>;
}

const Error: NextPage<IErrorPageProps> = ({ statusCode, content }) => {
  if (statusCode && statusCode !== 404) {
    return <InternalServerError />;
  }

  const { title: title404, text: text404 } = content || {};
  return <FdrError title={title404} text={text404} />;
};

export default Error;
