import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const MarkdownText = styled.div(
  sx2CssThemeFn({
    paddingTop: '216px',
    '.markdown-component': {
      h1: {
        fontSize: ['32px', '42px'],
        mb: [4, '40px'],
      },
    },
  })
);
