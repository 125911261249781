import React, { ReactElement } from 'react';
import { FdrTextBlock } from '@hotelplan/fdr.regular.basis.fdr-text-block';
import { TextBlockContainer } from 'components/domain/text-block/TextBlockContainer';
import { MarkdownText } from './fdr-error.styles';

interface IFdrErrorProps {
  title: string;
  text: string;
}

export function FdrError({ title, text }: IFdrErrorProps): ReactElement {
  return (
    <MarkdownText>
      <TextBlockContainer>
        <FdrTextBlock
          title={title}
          text={text}
          className={`markdown-component`}
        />
      </TextBlockContainer>
    </MarkdownText>
  );
}
